import '../App.css';
import Heading from "./Heading";
import Content from "./Content";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Heading />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
