const wpSites = [
    {
        id: 1,
        name: "www.CiprianKis.com",
        siteUrl: "https://Cipriankis.com",
        builtWith: "Customised Wordpress Theme",
        siteType: "Photography Portfolio",
    },
    {   
        id: 2,
        name: "www.AdrianTaxAgent.com",
        siteUrl: "https://adriantaxagent.com",
        builtWith: "Customised Wordpress Theme - Includes registration form, signature input field, filled out PDF",
        siteType: "Local Business, Tax Agent",
    },
    {   
        id: 3,
        name: "www.ClyaBeauty.com",
        siteUrl: "https://clyabeauty.com",
        builtWith: "From scratch - HTML, CSS, JS",
        siteType: "Local Business, Beauty salon and Beauty courses",
    }
]
export default wpSites;