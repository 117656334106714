import React from "react"

var currentYear = new Date().getFullYear()

function Footer() {
    return <footer className="App-footer">
        <p>Created by Ciprian Kis - © {currentYear}</p>
      </footer>;
}

export default Footer;