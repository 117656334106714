import React from "react";
import wpSites from './wp_sites';
import Card from "./Card";

function createCard(wpSites) {
    return <Card 
        key={wpSites.id}
        sitename={wpSites.name}
        siteadress={wpSites.siteUrl}
        sitetech={wpSites.builtWith}
        sitetype={wpSites.siteType}
    />
}

function Content() {
    return <div className="App-content">
        {wpSites.map(createCard)}
    </div>
}

export default Content;