import React from "react";

function Card(props) {
    return (
    <div className="card">
        <div>

            <h2>{props.sitename}</h2>
        </div>
        <div>
            <a href={props.siteadress}>Visit</a>
        </div>
        <div>
            <h2>{props.sitetech}</h2>
        <hr />
            <h2>{props.sitetype}</h2>
        </div>
    </div>
    );
}

export default Card;